.chatbot-dify .chat-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #556ee6;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.chatbot-dify .chat-icon.hidden {
  display: none;
}

.chatbot-dify .chatbot-container {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 90%;
  max-width: 400px;
  height: calc(100% - 200px);
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  padding: 16px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1000;
}

.chatbot-dify .chatbot-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  position: relative;
  height: 50px;
}

.chatbot-dify .chatbot-header h2 {
  font-size: 32px;
  font-weight: bold;
  color: #556ee6;
  margin: 0;
  padding: 0;
  text-align: center;
  flex: 1;
}

.chatbot-dify .logo-left,
.chatbot-dify .logo-right {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.3s ease;
  /* Aggiunta di una transizione per la trasformazione */
}

.chatbot-dify .logo-left {
  left: 10px;
}

.chatbot-dify .logo-right {
  right: 10px;
}

.chatbot-dify .logo-left.logo-spin,
.chatbot-dify .logo-right.logo-spin {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: translateY(-50%) rotate(0deg);
  }
  100% {
    transform: translateY(-50%) rotate(360deg);
  }
}

.chatbot-dify .chat-history {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 16px;
}

.chatbot-dify .user-message {
  text-align: right;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #cfd6f6;
  border-radius: 8px;
}

.chatbot-dify p {
  margin-bottom: 10px;
}

.chatbot-dify .bot-message {
  text-align: left;
  margin-bottom: 10px;
  padding: 10px;
  background-color: aliceblue;
  border-radius: 8px;
}

.chatbot-dify .input-container {
  display: flex;
}

.chatbot-dify input[type="text"] {
  flex: 1;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #d6d6d6;
  margin-right: 8px;
}

.chatbot-dify button {
  padding: 10px 16px;
  border-radius: 8px;
  border: none;
  background-color: #556ee6;
  color: #fff;
  cursor: pointer;
}

.chatbot-dify button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.chatbot-dify .loading-message {
  text-align: center;
  font-style: italic;
  color: #929292;
}

/* Regole CSS per la reattività */

@media (max-width: 768px) {
  .chatbot-dify .chatbot-container {
    bottom: 70px;
    right: 10px;
    width: calc(100% - 20px);
  }
}

@media (max-width: 480px) {
  .chatbot-dify .chat-icon {
    bottom: 20px;
  }
  .chatbot-dify .chatbot-container {
    bottom: 70px;
    right: 5px;
    left: 5px;
    width: calc(100% - 10px);
    border-radius: 8px;
  }
  .chatbot-dify .input-container input[type="text"] {
    font-size: 14px;
  }
  .chatbot-dify .input-container button {
    font-size: 14px;
    padding: 8px 12px;
  }
}

.chatbot-dify .icon-transition {
  opacity: 0;
  transform: scale(0.5);
}

.chatbot-dify .icon-enter {
  opacity: 1;
  transform: scale(1);
}
