/* PaymentForm.css */

.StripeElement {
    margin: 15px 0;
}

.payment-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    background-color: #f5f5f5;
    /* Background color */
}

.payment-form {
    /* White background */
    background-color: #ffffff;
    /* Shadow effect */
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 25px;
    margin: 25px;
    border-radius: 8px;
}

@media (min-width: 577px) {
    .payment-form {
        width: 400px;
    }
}

.stripe-button {
    background-color: #625afa;
    /* Stripe blue color */
    color: #ffffff;
    /* White text color */
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 25px;
}

.stripe-button:disabled {
    background-color: #bfbfbf;
    /* Grayed-out color for disabled state */
    cursor: not-allowed;
}