.App {
  background-image: url("../images/hero-5.jpg");
  height: 100vh;
}

h1 {
  font-size: 2em;
  color: #333;
  text-align: center;
  /* Center text within the container */
}

.payment-modal p {
  font-size: 1.2em;
  margin-top: 20px;
  color: #666;
  text-align: center;
  /* Center text within the container */
}

input {
  width: 80%;
  padding: 10px;
  margin-top: 20px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.btn-payment-methods {
  background-color: transparent;
  color: white;
  max-width: 150px;
  max-height: 150px;
  border-radius: 5px;
}

.btn-payment-methods:hover {
  transition: transform 0.3s;
  /* Animation */
  transform: scale(1.1);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.btn-payment-methods > img {
  border-radius: 5px;
  max-width: 100%;
  max-height: 100%;
}

/* CARD */

.cards-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.card-existing,
.card-new {
  flex: 1;
}

.card-representation,
.new-card-option {
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  margin-bottom: 10px;
  cursor: pointer;
}

.new-card-option.selected {
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.8);
  /* Crea un alone più marcato per indicare la selezione */
}

.card-button {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.card-front:before {
  content: "";
  inset: 0;
  position: absolute;
  transform: translate3d(0, 0, 0.01px);
  background-image: var(--glitter), var(--glitter),
    linear-gradient(120deg, black 25%, white, black 75%);
  background-size: 100% 100%, 80% 80%, 200% 200%;
  background-blend-mode: multiply, multiply, overlay;
  background-position: 50% 50%, 50% 50%, 50% 50%;
  mix-blend-mode: color-dodge;
  filter: brightness(2) contrast(0.8);
  animation: bg var(--duration) ease infinite;
}

.card-front {
  display: grid;
  position: relative;
  font-size: 0.5em;
  transform: translate3d(0, 0, 0.01px);
  width: 90vw;
  max-width: 580px;
  aspect-ratio: 3/2;
  border-radius: 3.5% 3.5% 3.5% 3.5% / 5% 5% 5% 5%;
  background-image: url(https://simey-credit-card.netlify.app/img/bgs/default.jpg);
  background-size: cover;
  box-shadow: 0 30px 40px -25px rgba(15, 5, 20, 1),
    0 20px 50px -15px rgba(15, 5, 20, 1);
  overflow: hidden;
  animation: tilt var(--duration) ease infinite;
  image-rendering: optimizequality;
}

.card-front:after {
  content: "";
  background: none, none,
    linear-gradient(
      125deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.4) 0.1%,
      rgba(255, 255, 255, 0) 60%
    );
  background-size: 200% 200%;
  mix-blend-mode: hard-light;
  animation: bg var(--duration) ease infinite;
}

.card-front * {
  font-family: PT Mono, monospace;
}

.card-logo,
.card-expiry,
.card-name,
.card-number,
.card-chip,
.card-icon {
  color: #ccc;
  position: absolute;
  margin: 0;
  padding: 0;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  /* font-size: clamp(0.75rem, 2.8vw + 0.2rem, 1.1rem); */
  font-size: clamp(0.75rem, 1.2vw + 0.2rem, 0.4rem);
  inset: 5%;
  text-shadow: -1px -1px 0px rgba(255, 255, 255, 0.5),
    1px -1px 0px rgba(255, 255, 255, 0.5), 1px 1px 0px rgba(0, 0, 0, 0.5),
    1px -1px 0px rgba(0, 0, 0, 0.5);
  z-index: 5;
}

.card-name,
.card-number,
.card-expiry {
  background-image: linear-gradient(to bottom, #ededed 20%, #bababa 70%), none,
    linear-gradient(
      120deg,
      transparent 10%,
      white 40%,
      white 60%,
      transparent 90%
    );
  background-size: cover, cover, 200%;
  background-position: 50% 50%;
  background-blend-mode: overlay;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  animation: bg var(--duration) ease infinite;
}

.card-number {
  font-family: PT Mono, monospace;
  text-align: center;
  /* font-size: clamp(1rem, 8vw - 0.5rem, 2.5rem); */
  font-size: clamp(0.75rem, 1.2vw + 0.2rem, 0.4rem);
  letter-spacing: 0.025em;
  top: 60%;
  bottom: auto;
}

.card-expiry,
.card-name {
  top: auto;
}

.card-name {
  right: auto;
  max-width: 180px;
  line-height: 1.2;
  text-align: left;
}

.card-expiry {
  left: auto;
}

.card-logo {
  bottom: auto;
  left: auto;
  width: 15%;
  filter: invert(1) saturate(0) brightness(1) contrast(1.2);
  mix-blend-mode: screen;
}

.card-chip {
  display: grid;
  place-items: center;
  width: 14%;
  aspect-ratio: 5/4;
  left: 10%;
  top: 30%;
  border-radius: 10% 10% 10% 10% / 15% 15% 15% 15%;
  background-image: none, none,
    linear-gradient(120deg, #777 10%, #ddd 40%, #ddd 60%, #777 90%);
  background-size: 200% 200%;
  background-position: 50% 50%;
  overflow: hidden;
  animation: bg var(--duration) ease infinite;
}

.card-chip svg {
  display: block;
  width: 90%;
  fill: none;
  stroke: #444;
  stroke-width: 2;
}

.card-contactless {
  position: absolute;
  left: 23%;
  top: 30%;
  width: 12%;
  rotate: 90deg;
  stroke-width: 1.25;
  stroke: currentColor;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  opacity: 0.5;
}

.card-icon {
  width: 25%;
  bottom: auto;
  right: auto;
  top: 0;
  left: 15px;
  filter: invert(1) hue-rotate(180deg) saturate(5) contrast(2);
}

@keyframes card-tilt {
  0%,
  100% {
    transform: translate3d(0, 0, 0.01px) rotateY(-20deg) rotateX(5deg);
  }
  50% {
    transform: translate3d(0, 0, 0.01px) rotateY(20deg) rotateX(5deg);
  }
}

@keyframes card-card-bg {
  0%,
  100% {
    background-position: 50% 50%, calc(50% + 1px) calc(50% + 1px), 0% 50%;
  }
  50% {
    background-position: 50% 50%, calc(50% - 1px) calc(50% - 1px), 100% 50%;
  }
}

/* main {
  display: grid;
  grid-template-rows: minmax(20px, 100px) 1fr;
  place-items: center;
  min-height: 100%;
  perspective: 1000px;
}

body {
  color: white;
  background: #333844;
  font-family: "Heebo", sans-serif;
  background: url(https://images.pexels.com/photos/3612932/pexels-photo-3612932.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  backdrop-filter: blur(5px);
}

body,
html {
  height: 100%;
  padding: 0;
  margin: 0;
} */

#card-chip,
#card-contactless {
  display: none;
}
