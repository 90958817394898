/* DatePicker.css */

.react-datepicker__header {
    background-color: #556ee6;
    color: white;
    /* Optionally, change the text color for better contrast */
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    color: white;
}

.react-datepicker__day-name {
    color: white;
}

.react-datepicker__day--selected {
    background-color: #556ee6;
}

.react-datepicker__navigation-icon::before {
    border-color: white;
}

.react-datepicker__day:hover {
    background-color: #34c38f;
    color: white;
}